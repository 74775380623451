import React from 'react';
import { Form, withFormik, FastField, ErrorMessage, Field } from 'formik';
import Recaptcha from 'react-google-recaptcha';
import * as Yup from 'yup';
import { FormGroup, Input, Textarea, Error } from '../../ui/form'
import { Row, Col } from '../../ui/wrapper'
import Button from '../../ui/button'
import { Center, InputField } from './styles';
import Anchor from '../../../components/ui/anchor'


const ConsultForm = ({ setFieldValue, isSubmitting, values, errors, touched }) => (
    <Form
        name="navbar-cta-form"
        method="post"
        data-netlify="true"
        data-netlify-recaptcha="true"
        data-netlify-honeypot="bot-field"
    >
        <Row>
            <Col lg={12}>
                <FormGroup mb="20px">
                    <Field
                        name="project"
                        id="project"
                        aria-label="project"
                        as="select"
                        hover="2"
                        error={touched.project && errors.project}
                    >
                        <option value="">What is the type of your project? *</option>
                        <option value="Custom software">Custom software</option>
                        <option value="Mobile app">Mobile app</option>
                        <option value="Web">Web app or website</option>
                        <option value="Machine learning or AI">Machine learning or AI</option>
                        <option value="Virtual or augmented reality">Virtual or augmented reality</option>
                        <option value="Other">Other types of projects</option>
                    </Field>
                    <ErrorMessage component={Error} name="project" />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <FormGroup mb="20px">
                    <Field
                        name="tunnel"
                        id="tunnel"
                        aria-label="tunnel"
                        as="select"
                        hover="2"
                        error={touched.tunnel && errors.tunnel}
                    >
                        <option value="">How did you find out about us? *</option>
                        <option value="Web search">I did a web search and you poped up</option>
                        <option value="Social media">I saw you on social media (Facebook, Instagram, LinkedIn...)</option>
                        <option value="Platforms">I saw your work on platforms (Clutch, Awwwards...)</option>
                        <option value="Article">I read an article about you</option>
                        <option value="Recommendation">I got a recommendation</option>
                        <option value="Other">Other</option>
                    </Field>
                    <ErrorMessage component={Error} name="tunnel" />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={12} mb="20px">
                <p>Need NDA first? E-mail us at <Anchor path="mailto:hello@sixvertex.com"> hello@sixvertex.com</Anchor></p>
            </Col>
        </Row>
        <Row gutters={{ lg: 20 }}>
            <Col lg={6}>
                <FormGroup mb="20px">
                    <Input
                        as={FastField}
                        type="text"
                        name="name"
                        component="input"
                        aria-label="name"
                        placeholder="Your/ Company Name *"
                        error={touched.name && errors.name}
                    />
                    <ErrorMessage component={Error} name="name" />
                </FormGroup>
            </Col>
            <Col lg={6}>
                <FormGroup mb="20px">
                    <Input
                        id="email"
                        aria-label="email"
                        component="input"
                        as={FastField}
                        type="email"
                        name="email"
                        placeholder="Email address *"
                        error={touched.email && errors.email}
                    />
                    <ErrorMessage component={Error} name="email" />
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <FormGroup mb="20px">
                    <Textarea
                        as={FastField}
                        component="textarea"
                        aria-label="message"
                        id="message"
                        rows="2"
                        type="text"
                        name="message"
                        placeholder="Tell us about the project *"
                        error={touched.message && errors.message}
                    />
                    <ErrorMessage component={Error} name="message" />
                </FormGroup>
            </Col>
        </Row>
        {values.name && values.email && values.message && (
            <InputField>
                <FastField
                    component={Recaptcha}
                    sitekey='6LfNndUZAAAAAL8_y_pmOMr7S-NygRaveUAPJ-FK'
                    name="recaptcha"
                    onChange={value => setFieldValue('recaptcha', value)}
                />
                <ErrorMessage component={Error} name="recaptcha" />
            </InputField>
        )}
        {values.success && (
            <InputField>
                <Center>
                    <p><span role="img" aria-label="tick">✅</span>  Your message has been successfully sent, Our team will contact you ASAP. In the meantime, feel free to contact us on social channels or call us if your project requires immediate attention.</p>
                </Center>
            </InputField>
        )}
        <Center>
            <p><small>By submitting this form, you confirm that you have read and agree to our <Anchor path="/privacy-policy">Privacy Policy.</Anchor></small></p>
            <Button type="submit" disabled={isSubmitting}>
                Send Message
        </Button>
        </Center>
    </Form>
);

export default withFormik({
    mapPropsToValues: () => ({
        project: '',
        tunnel: '',
        name: '',
        email: '',
        message: '',
        recaptcha: '',
        success: false,
    }),
    validationSchema: () =>
        Yup.object().shape({
            project: Yup.string().required('Project type is required'),
            tunnel: Yup.string().required('Source of referral is required'),
            name: Yup.string().required('Name is required'),
            email: Yup.string()
                .email('Invalid email address')
                .required('Email is required'),
            message: Yup.string().required('Message is required'),
            recaptcha: Yup.string().required('🤖 Robots are not welcome yet!'),
        }),
    handleSubmit: async ({ project, tunnel, name, email, message, recaptcha }, { setSubmitting, resetForm, setFieldValue }) => {
        try {
            const encode = data =>
                Object.keys(data)
                    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
                    .join('&');
            await fetch('/?no-cache=1', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: encode({
                    'form-name': 'navbar-cta-form',
                    project,
                    tunnel,
                    name,
                    email,
                    message,
                    'g-recaptcha-response': recaptcha,
                }),
            });
            await setSubmitting(false);
            await setFieldValue('success', true);
            setTimeout(() => resetForm(), 3000);
        } catch (err) {
            setSubmitting(false);
            setFieldValue('success', false);
            alert('Something went wrong, please try again!') // eslint-disable-line
        }
    },
})(ConsultForm);



